import axios from "axios";

axios.defaults.withCredentials = true;

const Store = {
  async getStore() {
    try {
      const response = await axios.get(process.env.REACT_APP_API_DOMAIN + "/api/store/getstore");
      let { success, redirect } = response.data;
      if (success) return { success: true, redirect: redirect };
      else return { success: false, redirect: redirect };
    } catch (error) {
      return { success: false, redirect: false };
    }
  },
  async getProducts() {
    try {
      const response = await axios.get(process.env.REACT_APP_API_DOMAIN + "/api/store/products");
      let { success, products, sid } = response.data;
      if (success) return { success: true, products: products, sid: sid };
      else return { success: false };
    } catch (error) {
      return { success: false };
    }
  },
  async getPurchases() {
    try {
      const response = await axios.get(process.env.REACT_APP_API_DOMAIN + "/api/store/purchase");
      let { success, purchases } = response.data;
      if (success) return { success: true, purchases: purchases };
      else return { success: false };
    } catch (error) {
      return { success: false };
    }
  },
  async getAdminPurchases(page) {
    try {
      const response = await axios.get(process.env.REACT_APP_API_DOMAIN + "/api/store/purchaseadmin/" + page);
      let { success, purchases } = response.data;
      if (success) return { success: true, purchases: purchases };
      else return { success: false };
    } catch (error) {
      return { success: false };
    }
  },
  async getAdminPurchasesV2() {
    try {
      const response = await axios.get(process.env.REACT_APP_API_DOMAIN + "/api/store/purchaseadmin/");
      let { success, purchases } = response.data;
      if (success) return { success: true, purchases: purchases };
      else return { success: false };
    } catch (error) {
      return { success: false };
    }
  },
  async purchase(sid, name, email, nif, product, payment, mbwayphone, extra) {
    try {
      const response = await axios.post(process.env.REACT_APP_API_DOMAIN + "/api/store/purchase", {
        sid: sid,
        name: name,
        email: email,
        nif: nif,
        product: product,
        payment: payment,
        mbwayphone: mbwayphone,
        extra: extra,
      });
      let { success, url } = response.data;
      if (success) return { success: true, url: url };
      else return { success: false };
    } catch (error) {
      return { success: false };
    }
  },
};

export default Store;
